export const WORDS =
[
    "about",
    "amber",
    "apple",
    "april",
    "arrow",
    "beach",
    "beard",
    "begin",
    "black",
    "blink",
    "block",
    "brave",
    "bread",
    "break",
    "bridge",
    "brown",
    "brush",
    "build",
    "burst",
    "cabin",
    "camel",
    "candy",
    "chair",
    "chalk",
    "charm",
    "chase",
    "child",
    "climb",
    "cloud",
    "clown",
    "color",
    "creek",
    "crime",
    "crown",
    "curve",
    "dance",
    "death",
    "debut",
    "demon",
    "ditch",
    "dream",
    "drink",
    "drive",
    "drone",
    "earth",
    "elbow",
    "email",
    "empty",
    "enemy",
    "error",
    "fairy",
    "false",
    "fancy",
    "fatal",
    "favor",
    "fence",
    "fiery",
    "fight",
    "final",
    "flame",
    "flare",
    "flash",
    "fleet",
    "flesh",
    "flood",
    "floor",
    "flour",
    "fluid",
    "flute",
    "focal",
    "force",
    "forge",
    "forma",
    "forte",
    "fount",
    "frame",
    "freak",
    "fresh",
    "frost",
    "fruit",
    "funny",
    "furry",
    "gator",
    "giant",
    "glide",
    "globe",
    "glory",
    "goose",
    "grace",
    "grain",
    "grape",
    "green",
    "greet",
    "grind",
    "group",
    "grove",
    "guest",
    "guide",
    "happy",
    "heavy"
];
